var $ = require("jquery");
var jQuery = $;
require("../lib/slick.min");

//OS check========================/
function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

if (getOS() == "Windows") {
  $("body").addClass("os-windows");
}

if (getOS() == "iOS") {
  $("body").addClass("os-ios");
}
try {
  if (navigator.userAgent.search("Chrome") >= 0) {
    $("body").addClass("chrome-browser");
  } else if (navigator.userAgent.search("Firefox") >= 0) {
    $("body").addClass("firefox-browser");
    $("head").append(
      "<style>.body-margin {margin-right:" + scrollbarWidth() + "px}</style>"
    );
  } else if (
    navigator.userAgent.search("Safari") >= 0 &&
    navigator.userAgent.search("Chrome") < 0
  ) {
    $("body").addClass("safari-browser");
  } else if (navigator.userAgent.search("Opera") >= 0) {
    $("body").addClass("opera-browser");
  }
} catch (error) {
  console.error(error);
}

var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if (/IEMobile|Windows Phone/i.test(navigator.userAgent)) {
  var windowsPhone = true;
}

if (screen.width < 1040 && screen.height > 1040) {
  var iPad = true;
  $("body").addClass("ipad");
}
//OS check========================/

//Popup fix========================/
function popupFunction() {
  if (iOS == true || windowsPhone == true) {
    var scrollTop = $(window).scrollTop();
    var windowH = $(window).innerHeight();

    $("body").addClass("pop-up-open");
    $("body").attr("data-top", scrollTop);
    if (windowsPhone == true) {
      $("body").css("top", scrollTop);
    }
    $("body").css({
      top: "-" + scrollTop + "px",
    });
  }
}

function popupCloseFunction() {
  if (iOS == true || windowsPhone == true) {
    var scTop = $("body").attr("data-top");
    if (windowsPhone == true) {
      var scTop = $("body").css("top");
    }
    var suffix = scTop.match(/\d+/);
    $("body").removeClass("pop-up-open");
    $("body").removeAttr("style");

    $("html, body").scrollTop(parseInt(suffix));
  }
}
//Popup fix========================/

//Browser check========================/
if (navigator.userAgent.search("Chrome") >= 0) {
  $("body").addClass("chrome-browser");
} else if (navigator.userAgent.search("Firefox") >= 0) {
  $("body").addClass("firefox-browser");
} else if (
  navigator.userAgent.search("Safari") >= 0 &&
  navigator.userAgent.search("Chrome") < 0
) {
  $("body").addClass("safari-browser");
} else if (navigator.userAgent.search("Opera") >= 0) {
  $("body").addClass("opera-browser");
}

if ($("#slider_container_wrap").length) {
  $("#slider_container_wrap").slick({
    arrows: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    swipe: true,
    fade: true,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    touchThreshold: 100,
    touchMove: true,
    draggable: true,
    autoplay: true,
    mobileFirst: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 500,
    autoplaySpeed: 3000,
    prevArrow: $(".slider_container .arrow.prev"),
    nextArrow: $(".slider_container .arrow.next"),
  });
}

// Whatsup slider
if ($("#whatsup-slider").length) {
  $("#whatsup-slider").slick({
    arrows: false,
    dots: true,
    dotsClass: "vertical-dots",
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    swipe: true,
    fade: true,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    touchThreshold: 100,
    touchMove: true,
    draggable: true,
    autoplay: true,
    mobileFirst: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    speed: 500,
    autoplaySpeed: 5000,
  });
}
// Whatsup slider

//Tour slider========================/
if ($("#events-slider").length) {
  $("#events-slider").slick({
    arrows: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    swipe: true,
    fade: false,
    variableWidth: true,
    pauseOnHover: true,
    touchMove: true,
    draggable: true,
    autoplay: true,
    speed: 600,
    autoplaySpeed: 7000,
    prevArrow: $(".main-events .arrow.prev"),
    nextArrow: $(".main-events .arrow.next"),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          touchMove: true,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  });
}
//Tour slider========================/

//Retro slider========================/
if ($("#retro-slider").length && screen.width > 1040 && !iPad) {
  $("#retro-slider").slick({
    arrows: true,
    dots: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: true,
    swipe: true,
    fade: false,
    variableWidth: true,
    pauseOnHover: true,
    touchMove: true,
    draggable: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 8000,
    prevArrow: $(".retro-events .arrow.prev"),
    nextArrow: $(".retro-events .arrow.next"),
  });
}
//Retro slider========================/

$("body").append(
  "<div id='setHeight' style='position:fixed; top:0; bottom:0;left:0;right:0;z-index:-10'></div>"
);
var activeHeight = $("#setHeight").innerHeight();
$("#setHeight").remove();
$(".js_height").innerHeight(activeHeight * 0.8);
$(".whatsup_js_height").innerHeight(activeHeight * 0.66);

//Mobile menu ========================/
$("#mobile_btn").on("click", function () {
  var thisBtn = $(this),
    menu = $("#menu_wrap").fadeIn();

  $("html, body").addClass("locked");
  $("#menu-close, #mobile_btn").addClass("opened");
  popupFunction();
});

$("#menu-close").on("click", function () {
  $("html, body").removeClass("locked");
  $("#menu-close, #mobile_btn").removeClass("opened");
  $("#menu_wrap").fadeOut();
  popupCloseFunction();
});
//Mobile menu ========================/

//Usermenu ========================/
try {
  $("#userlink").on("click", function (e) {
    e.preventDefault();

    var thisLink = $(this),
      usermenu = $("#usermenu");

    usermenu.fadeToggle(100);
  });

  $(document).on("mouseup", function (e) {
    var div = $("#userblock");
    if (!div.is(e.target) && div.has(e.target).length === 0) {
      $("#usermenu").fadeOut(100);
    }
  });
} catch (error) {
  console.log(error);
}

//Usermenu ========================/

//Select change ========================/
$(".filters select").on("change", function () {
  $(this).addClass("selected");
});
//Select change ========================/
